export default {
  isDview: true,
  drawer: false,
  test: 'store test',
  locale: 'es',
  languages: ['es', 'en'],
  dtouchLanguages: ['es'],
  bookingLanguages: ['es'],
  timezone: 'Atlantic/Canary',
  loading: false,
  environment: null,
  environmentsFilter: [],
  dviewcontentWorkspace: null,
  dtouchWorkspace: null,
  bookingWorkspace: null,
  turismoWorkspace: null,
  playersWorkspace: null,
  bookingWorkspaceAllowReservation: false,
  bookingActtivAllowReservation: false,
  bookingWorkspaceData: {
    scheduleTypes: null,
  },
  bookingRefreshReservationList: null,
  bookingUpdateAvailability_resource: null,
  dfilesWorkspace: null,
  queueWorkspace: null,
  mainAlert: null,
  sidebarBadges: [],
  dtouchDraggableMenuUpdated: null, // fastEdit simulation delete element
  dtouchMenuEdit: null,
  dtouchContentEdit: null,
  dtouchSortUpdated: null,
  dtouchSimulationLocale: 'es',
  dtouchSimulationHide: false,
  dtouchSimulationShowContentHide: false,
  dtouchSimulationShowFullSimulation: true,//process.env.NODE_ENV === 'development',
  dtouchSimulationShowFastEdit: false,
  dtouchSimulationAllowSort: true,
  dtouchHideDtouchSettingForm: false,
  dtouchIsTouchPublic: false,

  dtouchDeeplTranslateActive: true,
  dtouchDeeplTranslateLocale: 'es',


  dtouchDishOrder: null,
  mainPath: null,
  cacheFormData: false,
}
