import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  // Enabling customProperties will also generate a css variable for each theme color, which you can then use in your components’ <style> blocks.
  theme: {
    options: { customProperties: true },
  },
})

