/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import utils from '@/services/utils.js'

// import CKEditor from '@ckeditor/ckeditor5-vue'

// Routes
import privatePaths from './privatePaths'
import publicPaths from './publicPaths'

function route (path, view, name, meta) {
  return {
    name: name || view,
    path,
    meta,
    component: (resovle) => import(
      `@/views/${view}.vue`
    ).then(resovle)
  }
}

Vue.use(Router)
// Vue.use(CKEditor)

const routes = utils.isPrivate() ? privatePaths : publicPaths

// Create a new router
const router = new Router({
  routes: routes.map(path => route(path.path, path.view, path.name, path.meta)),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

Vue.use(Meta)

export default router
