import axios from 'axios'
import store from '@/store'

const apiServices = {
  prepareError (v) {
    if(!v.response) return
    const { status, data } = v.response
    if (status === 403) window.location.href = '/#/login'
    else if (status === 500 && data.error ) throw (data.error)
  },
  setToken (v) {
    //axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
    axios.defaults.headers.common['Authorization'] = `Bearer ${v}` 
    localStorage.setItem(`jwt`, v ? v : '')
    return
  },
  getToken () {
    const aux = localStorage.getItem(`jwt`)
    //axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
    axios.defaults.headers.common['Authorization'] = `Bearer ${aux}` 
    return aux && aux.length > 0 ? aux : null
  },
  getAPIURL (environment, path, id) {
    let urlBase = ''
    switch (environment) {
      case 'meteo': 
        urlBase = process.env.VUE_APP_API_URL_METEO
        break
      case 'dviewcontent': 
        urlBase = process.env.VUE_APP_API_URL_DVIEWCONTENT
        break
      case 'dfiles': 
        urlBase = process.env.VUE_APP_API_URL_DFILES
        break
      case 'queue': 
        urlBase = process.env.VUE_APP_API_URL_QUEUE
        break
      case 'dtouch': 
        urlBase = process.env.VUE_APP_API_URL_DTOUCH
        break
      case 'booking': 
        urlBase = process.env.VUE_APP_API_URL_BOOKING
        break
      case 'turismo': 
        urlBase = process.env.VUE_APP_API_URL_TURISMO
        break
      case 'menuExt': 
        urlBase = process.env.VUE_APP_API_URL_MENU_EXT
        break
      case 'players': 
        urlBase = process.env.VUE_APP_API_URL_PLAYERS
        break
      default:
        urlBase = process.env.VUE_APP_API_URL
        break
    }
    let www = '' //process.env.VUE_APP_ENV === 'development' ? '' : 'www.'
    let result = `${window.location.protocol}//${www}${urlBase}/${path}`
    if (id) result = `${result}/${id}`
    return result.concat('?')
    //return environment === 'dtouch' ? result.concat('?') : `${result}?token=${this.getToken()}`
  },
  getAllWithoutLimit (environment, url, showLoading = true, query = '') {
    if (showLoading) store.state.app.loading = true
    const urlFinal = this.getAPIURL(environment, url).concat(`&start_index=${0}&item_per_page=${1000000}`)
    return axios.get(urlFinal.concat(query))
      .then(response => response.data)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      })
      .finally(() => { if (showLoading) store.state.app.loading = false })
  },
  getAll (environment, url, startIndex = 0, itemPerPage = 5, searchValue = '', visibilityFilter = '') {
    // store.state.app.loading = true
    const urlFinal = this.getAPIURL(environment, url).concat(`&start_index=${startIndex}&item_per_page=${itemPerPage}&search_value=${searchValue}&visibility_filter=${visibilityFilter}`)
    return axios.get(urlFinal)
      .then(response => response.data)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      })
      // .finally(() => { store.state.app.loading = false })
  },
  getItem (environment, url, id, showLoading = true) {
    if (showLoading) store.state.app.loading = true
    const urlFinal = this.getAPIURL(environment, url.concat(id))
    return axios.get(urlFinal)
      .then(response => response.data && response.data.length > 0 ? response.data[0] : null)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      }) 
      .finally(() => { if (showLoading) store.state.app.loading = false })   
  },
  addItem (environment, url, body) {
    store.state.app.loading = true
    return axios.post(this.getAPIURL(environment, url), body)
      .then(response => response)
      .catch((error) => {
        this.prepareError(error)
//        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      })
      .finally(() => { store.state.app.loading = false })
  },
  updateItem (environment, url, id, body) {
    store.state.app.loading = true
    return axios.patch(this.getAPIURL(environment, url.concat(id)), body)
      .then(response => response)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      })
      .finally(() => { store.state.app.loading = false })
  },
  deleteItem (environment, url, id, body) {
    store.state.app.loading = true
    return axios.delete(this.getAPIURL(environment, url.concat(id)), { data: body })
      .then(response => response)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      })
      .finally(() => { store.state.app.loading = false })
  },
  put (environment, url, body) {
    store.state.app.loading = true
    return axios.put(this.getAPIURL(environment, url), body)
      .then(response => response)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      })
      .finally(() => { store.state.app.loading = false })
  },
  getActiveData (environment, url, body) {
    store.state.app.loading = true
    return axios.post(this.getAPIURL(environment, url), body)
      .then(response => response)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      })
      .finally(() => { store.state.app.loading = false })
  },


  // STGE
  uploadFile (environment, body) {
    const token = environment === 'dtouch' ? process.env.VUE_APP_API_TOKEN_STGE_DTOUCH : null
    const url = `https://${process.env.VUE_APP_API_URL_STGE}/v1/private/files/?token=${token}`
    store.state.app.loading = true
    return axios.post(url, body)
      .then(response => response)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      })
      .finally(() => { store.state.app.loading = false })
  },
}

export default apiServices

