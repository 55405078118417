<template>
  <v-app>
    <loader />
    <component :is="layout" />
    <!-- <core-filter />

    <core-toolbar />

    <core-drawer />

    <core-view /> -->
  </v-app>
</template>

<script>
  const defaultLayout = 'public'
  import Loader from '@/components/Loader'
  export default {
    components: {
      Loader
    },
    computed: {
      layout () {
        return (this.$route.meta.layout || defaultLayout) + '-layout'
      }
    },
    mounted () {
      this.$vuetify.theme.themes.light.primary = process.env.VUE_APP_PRIMARYCOLOR
      this.$vuetify.theme.themes.light.secondary = process.env.VUE_APP_SECONDARYCOLOR
    }
  }
</script>

<style lang="scss">
  // @import '@/styles/index.scss';
</style>
