<template>
  <div style="text-align: center">
    <v-icon
      v-for="item in environments"
      :key="item.path"
      @click="handleFilter(item.name, environmentsFilter.indexOf(item.name) >= 0 ? false : true)"
      :style="{ opacity: environmentsFilter.indexOf(item.name) >= 0 ? 1 : 0.2 }"
      v-text="item.icon"
      :color="environmentsFilter.indexOf(item.name) >= 0 ? 'blue' : 'gray'"
      style="padding: 0 5px;"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import constants from '@/constants'
import api from '@/services/api'
import utils from '@/services/utils'
export default {
  data: () => ({
    environments: [],
  }),
  computed: {
    ...mapState('app',['locale', 'environmentsFilter']),
    environment () {
      return this.$route.meta.environment
    }
  },
  mounted () {
    api.getItem ('system', `v1/internal/environments/`, utils.getUser())
      .then(response => {
        this.environments = constants.environments.filter(x => response.Environments.indexOf(x.name) >= 0)
      })
  },
  methods: {
    handleFilter (v, active) {
      if (active) this.$store.state.app.environmentsFilter.push(v)
      else this.$store.state.app.environmentsFilter = this.$store.state.app.environmentsFilter.filter(x => x !== v)
      
      localStorage.setItem('environmentsFilter', this.$store.state.app.environmentsFilter.join(','))
    },
  },
}
</script>
