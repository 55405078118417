<template>
    <main-view />
</template>
<script>
  import MainView from '@/views/View.vue'
  export default {
    components: {
      MainView
    },
  }
</script>
