import { set, toggle } from '@/utils/vuex'

export default {
  setDrawer: set('drawer'),
  setImage: set('image'),
  setColor: set('color'),
  setEnterpriseName: set('enterpriseName'),
  setEnterpriseID: set('enterpriseID'),
  toggleDrawer: toggle('drawer'),
  setIsTotemAccessibility: set('isTotemAccessibility'),
  setTotemMenuSelected: set('totemMenuSelected')
}
