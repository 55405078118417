<template>
  <form>
    <v-layout wrap>
      <v-flex xs6>
        <v-text-field
          v-model="oldPassword"
          :error-messages="oldPasswordErrors"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          :label="$t('changePassword.oldPassword')"
          @click:append="show1 = !show1"
          @input="$v.oldPassword.$touch()"
          @blur="$v.oldPassword.$touch()"
          outlined
        />
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="password"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          :label="$t('changePassword.password')"
          :error-messages="passwordErrors"
          @click:append="show2 = !show2"
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
          outlined
        />
        <v-text-field
          v-model="repeatPassword"
          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show3 ? 'text' : 'password'"
          :label="$t('changePassword.repeatPassword')"
          :error-messages="repeatPasswordErrors"
          @click:append="show3 = !show3"
          @input="$v.repeatPassword.$touch()"
          @blur="$v.repeatPassword.$touch()"
          outlined
        />
        <!-- <br>
        <v-btn
          block
          color="primary"
          dark
          @click="handleChangePassword"
        >
          {{ $t('common.save') }}
        </v-btn> -->
      </v-flex>
      <v-flex xs6>
        <v-btn
          block
          color="secundary"
          dark
          @click="onCancel"
        >
          {{ $t('common.cancel') }}
        </v-btn>
      </v-flex>
      <v-flex xs6>
      <v-btn
          block
          color="primary"
          dark
          @click="handleChangePassword"
        >
          {{ $t('common.save') }}
        </v-btn>
      </v-flex>
    </v-layout>
  </form>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, sameAs } from 'vuelidate/lib/validators'

  export default {
    mixins: [validationMixin],
    props: {
      onSave: {
        type: Function,
        required: true
      },
      onCancel: {
        type: Function,
        required: true
      }
    },
    data: () => ({
      sheet: false,
      show1: false,
      show2: false,
      show3: false,
      oldPassword: '',
      password: '',
      repeatPassword: '',
    }),
    validations () { 
      return {
        oldPassword: { required },
        password: { required },
        repeatPassword: {
          sameAsPassword: sameAs('password')
        }
      }
    },
    computed: {
      oldPasswordErrors () {
          if (this.$v.oldPassword) {
            const errors = []
            if (!this.$v.oldPassword.$dirty) return errors
            !this.$v.oldPassword.required && errors.push(this.$t('changePassword.messages.oldPasswordRequired'))
            return errors
          }
        return null
      },
      passwordErrors () {
        if (this.$v.password) {
          const errors = []
          if (!this.$v.password.$dirty) return errors
          !this.$v.password.required && errors.push(this.$t('changePassword.messages.passwordRequired'))
          return errors
        }
        return null
      },
      repeatPasswordErrors () {
        const errors = []
        if (!this.$v.repeatPassword.$dirty) return errors
        !this.$v.password.required && errors.push(this.$t('changePassword.messages.passwordRequired'))
        !this.$v.repeatPassword.sameAsPassword && errors.push(this.$t('changePassword.messages.sameAsPassword'))
        return errors
      },
    },
    methods: {
      handleChangePassword () {
        this.$v.$touch()
        this.onSave(this.oldPassword, this.password)
      }
    }
  }
</script>
