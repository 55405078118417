<template>
  <div class="text-center" >
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <!--v-icon
          dark
          x-large 
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="menu = true"
        >
          mdi-account-circle
        </v-icon-->
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          text
          fab
        >
        <v-icon
          v-if="!avatar"
          dark
          x-large 
          color="primary"
        >
          mdi-account-circle
        </v-icon>
        <v-list-item-avatar
          v-else
        >
          <img
            :src="avatar"
            :alt="name"
          >
        </v-list-item-avatar>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon
                v-if="!avatar"
                dark
                x-large 
                color="primary"
              >
                mdi-account-circle
              </v-icon>
              <img
                v-else
                :src="avatar"
                :alt="name"
              >
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ name }}</v-list-item-title>
              <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <!--v-btn
                class="red"
                icon
              >
                <v-icon>mdi-hand-pointing-right</v-icon>
              </v-btn-->
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider />
          <v-layout wrap>
            <v-flex xs6>
              <v-btn
                text
                block
                :color="locale === 'es' ? 'primary' : '#ccc'"
                @click="handleChangeLocale('es')"
              >
                Español
              </v-btn>
            </v-flex>
            <v-flex xs6>
              <v-btn
                text
                block
                :color="locale === 'en' ? 'primary' : '#ccc'"
                @click="handleChangeLocale('en')"
              >
                English
              </v-btn>
            </v-flex>
          </v-layout>
        <v-divider />

        <v-list>
          <v-list-item>
            <change-password 
              :onChangeMessage="handleChangeMessage"
            />
          </v-list-item>
          <environments-filter />
          <v-list-item>
            <v-btn
              color="primary"
              block
              @click="handleGoTo('login', '/login')"
            >
            {{ $t('login.logout', locale)}}
            </v-btn>
            <!--v-list-item-action>
              <v-switch
                v-model="message"
                color="purple"
              ></v-switch>
            </v-list-item-action>
            <v-list-item-title>Enable messages</v-list-item-title-->
          </v-list-item>

          <!--v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="hints"
                color="purple"
              ></v-switch>
            </v-list-item-action>
            <v-list-item-title>Enable hints</v-list-item-title>
          </v-list-item-->
        </v-list>

        <!--v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="menu = false"
          >
            Save
          </v-btn>
        </v-card-actions-->
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import utils from '@/services/utils'
import api from '@/services/api'
import ChangePassword from './ChangePassword'
import EnvironmentsFilter from '@/components/EnvironmentsFilter'
export default {
  components: {
    ChangePassword,
    EnvironmentsFilter,
  },
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    avatar: null,
    name: '',
    email: '',
  }),
  computed: {
    ...mapState('app',['locale']),
  },
  mounted () {
    this.handleGetUserData()
  },
  methods: {
    handleGetUserData () {
      //axios.get(api.getAPIURL('system', 'authenticate'.concat('/', utils.getUser())))
      //axios.get(api.getAPIURL(this.$route.meta.environment, 'internal/users'.concat('/', utils.getUser())))
      axios.get(api.getAPIURL('system', 'v1/internal/users'.concat('/', utils.getUser())))
      .then(response => {
        this.name = response.data.name
        this.email = response.data.email ? response.data.email : ''
        this.avatar = response.data.avatar
      })
      .catch((err) => {
        if (err) {
          console.log('err', err)
        }
      })
    },
    handleGoTo (mainPath, path) {
      //if(mainPath !== this.$route.meta.mainPath) this.$router.push(path)
      if(path !== this.$route.path) this.$router.push(path)
    },
    handleChangeLocale (v) {
      this.$store.state.app.locale = v
    },
    handleChangeMessage (msg) {
      alert(msg)
    },
  },
}
</script>

