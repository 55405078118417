import store from '@/store'

const availability = {
  alternation (dateStart, dateActual, alternation, isByMonth) {
    if (isByMonth) {
        const diff = dateActual.getMonth() - dateStart.getMonth()
        if ((diff % alternation) === 0) return true
    } else {
        const dateActualAux = dateActual.setDate(dateActual.getDate() + (-1 * parseInt((dateActual.getUTCDay() === 0 ? 7 : dateActual.getUTCDay())-1, 10)))
        const dateStartAux = dateStart.setDate(dateStart.getDate() + (-1 * parseInt((dateStart.getUTCDay() === 0 ? 7 : dateStart.getUTCDay())-1, 10)))

        const diff = parseInt(((new Date(dateActualAux) - new Date(dateStartAux)) / (1000 * 60 * 60 * 24)) / 7, 10) 
      console.log(diff)
         
        if ((diff % alternation) === 0) return true
    }
    return false
  },
  check (v, daysToAdd = 0, timeZone = 'Europe/London') {
    const av = JSON.parse(JSON.stringify(v))
    const isDebug = false
    
    if (av.Per === null) return { date: true, time: true, finished: false, daysToAdd}
    
    // if (isDebug) console.log('aaaaa++++' , av)
    
    const result = { date: false, time: false, finished: false, daysToAdd: daysToAdd }
    
    let dActual = new Date(new Date().setDate(new Date().getDate() + parseInt(daysToAdd, 10)))

    // check time
    if (av.IsAllTimes) result.time = true
    else {
      const nowSplit = new Date().toLocaleTimeString('es-ES', {timeZone: timeZone}).split(':')
      const hNow = parseInt(nowSplit[0], 10)
      const mNow = parseInt(nowSplit[1], 10)
      const now = hNow * 60 + mNow

      if (isDebug) console.log(timeZone, new Date().toLocaleTimeString('es-ES'), nowSplit)

      if (av.TimeStart <= now && av.TimeEnd >= now) result.time = true
      if (daysToAdd === '0' && av.TimeEnd <= now) result.finished = true
      // console.log('jhm', timeZone, daysToAdd, end,now,'-----',  new Date().toLocaleTimeString("es-ES", {timeZone: timeZone}))
      if (isDebug) console.log(`Time:[${av.TimeStart}-${now}-${av.TimeEnd}]`)
    }

    // [X] PER = 1 fecha evento
    // [X] PER = 2 recurrencia
      // [X] REC = 1 semana
      // [X] REC = 0 mes
    // [X] PER = 0 fecha inicio-fin

    if (av.Per === 1) {
      if (isDebug) console.log('Entra por Evento')
      // ################################# fecha evento
      const dEvent = typeof av.DateStart === 'string' ? new Date (av.DateStart) : av.DateStart
      // const dEvent = Date.parse(av.Date, 'Y-m-d')

      /*
      result.date = (
        new Date(dEvent).getMonth() === new Date(dActual).getMonth() &&
        new Date(dEvent).getDate() === new Date(dActual).getDate() &&
        new Date(dEvent).getFullYear() === new Date(dActual).getFullYear()
      )*/
      result.date = dEvent.setHours(12, 0, 0, 0) === dActual.setHours(12, 0, 0, 0)
    } else if (av.Per === 2 || av.Per === 0) {
      if (isDebug) console.log('DateStart: ', av.DateStart)
      if (isDebug) console.log('DateEnd: ', av.DateEnd)

      let dStart = typeof av.DateStart === 'string' ? new Date (av.DateStart) : av.DateStart
      let dEnd = typeof av.DateEnd === 'string' ? new Date (av.DateEnd) : av.DateEnd
      // let dStart = Date.parse(av.DateStart, 'Y-m-d')
      // let dEnd = Date.parse(av.DateEnd, 'Y-m-d')
      // INI RepeatAnnually
      if (av.Per === 2 && av.IsRepeatAnnually && av.Rec === 0) {
        const yearDiff = new Date().getFullYear() - parseInt(av.DateStart.toString().substring(0, 4))
        if (yearDiff > 0) {
          let y = parseInt(av.DateStart.toString().split('-')[0]) + yearDiff
          let m = parseInt(av.DateStart.toString().split('-')[1])
          let d = parseInt(av.DateStart.toString().split('-')[2])
          dStart = Date.parse(''.concat(y.toString(),'-', ('000' + m.toString()).slice(-2),'-',('000' + d.toString()).slice(-2)), 'Y-m-d')
          y = parseInt(av.DateEnd.toString().split('-')[0]) + yearDiff
          m = parseInt(av.DateEnd.toString().split('-')[1])
          d = parseInt(av.DateEnd.toString().split('-')[2])
          dEnd = Date.parse(''.concat(y.toString(),'-', ('000' + m.toString()).slice(-2),'-',('000' + d.toString()).slice(-2)), 'Y-m-d')
          //console.log(''.concat(y.toString(),'-', ("000" + m.toString()).slice(-2),'-',("000" + d.toString()).slice(-2)))
        }
      }
      // END RepeatAnnually
      // const aDActual  = new Date(dActual).setHours(0, 0, 0)
      if (dActual.setHours(12, 0, 0, 0) >= dStart.setHours(12, 0, 0, 0) && (dActual.setHours(12, 0, 0, 0) <= dEnd.setHours(12, 0, 0, 0) || av.IsAllDays)) {
        // check datestart
        if (av.Per === 2) {
          if (isDebug) console.log('Entra por Recurrencia')
          // ################################# recurrencia
          if (av.Rec === 1) {
            if (isDebug) console.log('Entra por Semana')
            // --------------------------------- semana
            const dayWeek = dActual.getUTCDay()
            // if (isDebug) console.log('jhm', typeof av.DayOfWeek, av.DayOfWeek)
            if (typeof av.DayOfWeek === 'string') av.DayOfWeek = JSON.parse(av.DayOfWeek)
            if (av.DayOfWeek.indexOf(dayWeek) >= 0 && this.alternation(new Date(dStart), new Date(dActual), av.Alt, false)) result.date = true
          } else {
            if (isDebug) console.log('Entra por Mes')
            // --------------------------------- mes
            const dayMonth = dActual.getDate()
            if (typeof av.DayOfMonth === 'string') av.DayOfMonth = JSON.parse(av.DayOfMonth)
            if (av.DayOfMonth.indexOf(dayMonth) >= 0 && this.alternation(new Date(dStart), new Date(dActual), av.Alt, true)) result.date = true
          }
        } else {
          if (isDebug) console.log('Entra por INI-FIN')
          // ################################# fecha inicio-fin
            result.date = true
        }
      }
    }
    return result
  },
  checkIsActive (v, daysToAdd = 0, timeZone = 'Europe/London') {
    if (store.state.app.timezone) timeZone = store.state.app.timezone
    const aux = this.check(v, daysToAdd, timeZone)
    return aux.date && aux.time
  },
}

export default availability

