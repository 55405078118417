/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: '/login',
    // Relative to /src/views
    meta: { layout: 'public' },
    view: 'private/Login'
  },
  {
    path: '/dtouch-preview/:id/:type?',
    // Relative to /src/views
    meta: { layout: 'public' },
    view: 'public/dtouchPreview/Index'
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { layout: 'private', requiresAuth: true, mainPath: 'dashboard' },
    view: 'private/dashboard/Index'
  },
  {
    path: '/no-env',
    name: 'noEnv',
    meta: { layout: 'private', requiresAuth: true, mainPath: 'no-env' },
    view: 'private/NoEnv'
  },
  {
    path: '/',
    name: 'home',
    meta: { layout: 'private', requiresAuth: true, mainPath: 'dashboard' },
    view: 'private/dashboard/Index'
  },
  //PLAYER
  {
    path: '/players',
    name: 'players',
    meta: { layout: 'private', requiresAuth: true, environment: 'players', mainPath: 'dashboard' },
    view: 'private/players/Index'
  },
  {
    path: '/players/presentations',
    name: 'presentations',
    meta: { layout: 'private', requiresAuth: true, environment: 'players', mainPath: 'presentations' },
    view: 'private/players/presentations/Index'
  },
  {
    path: '/players/android',
    name: 'playersAndroid',
    meta: { layout: 'private', requiresAuth: true, environment: 'players', mainPath: 'playersAndroid' },
    view: 'private/players/android/Index'
  },
  {
    path: '/players/totems',
    name: 'playersTotems',
    meta: { layout: 'private', requiresAuth: true, environment: 'players', mainPath: 'playersTotems' },
    view: 'private/players/totems/Index'
  },
  {
    path: '/players/windows',
    name: 'playersWindows',
    meta: { layout: 'private', requiresAuth: true, environment: 'players', mainPath: 'playersWindows' },
    view: 'private/players/windows/Index'
  },
  {
    path: '/players/chromecast',
    name: 'playersCromecast',
    meta: { layout: 'private', requiresAuth: true, environment: 'players', mainPath: 'playersChromecast' },
    view: 'private/players/chromecast/Index'
  },
  {
    path: '/players/advance-search',
    name: 'playersAdvanceSearch',
    meta: { layout: 'private', requiresAuth: true, environment: 'players', mainPath: 'playersAdvanceSearch' },
    view: 'private/players/advanceSearch/Index'
  },
  {
    path: '/players/brightsign',
    name: 'playersBrightsign',
    meta: { layout: 'private', requiresAuth: true, environment: 'players', mainPath: 'playersBrightsign' },
    view: 'private/players/brightsign/Index'
  },
  //PLAYER
  // SYSTEM USER
  {
    path: '/sys',
    name: 'sys',
    meta: { layout: 'sys', requiresAuth: true, environment: 'sys', mainPath: 'dashboard' },
    view: 'sys/Index'
  },
  {
    path: '/sys/dashboard',
    name: 'sysDashboard',
    meta: { layout: 'sys', requiresAuth: true, environment: 'sys', mainPath: 'dashboard' },
    view: 'sys/Index'
  },
  {
    path: '/sys/users',
    name: 'sysUser',
    meta: { layout: 'sys', requiresAuth: true, environment: 'sys', mainPath: 'users' },
    view: 'sys/users/Index'
  },
  {
    path: '/sys/dviewcontent',
    name: 'sysDviewcontent',
    meta: { layout: 'sys', requiresAuth: true, environment: 'sys', mainPath: 'dviewcontent' },
    view: 'sys/dviewcontent/Index'
  },
  {
    path: '/sys/dtouch',
    name: 'sysDtouch',
    meta: { layout: 'sys', requiresAuth: true, environment: 'sys', mainPath: 'dtouch' },
    view: 'sys/dtouch/Index'
  },
  {
    path: '/sys/dtouch/:id',
    name: 'sysDtouchItem',
    meta: { layout: 'sys', requiresAuth: true, environment: 'sys', mainPath: 'dtouch' },
    view: 'sys/dtouch/Index'
  },
  {
    path: '/sys/dfiles',
    name: 'sysDfiles',
    meta: { layout: 'sys', requiresAuth: true, environment: 'sys', mainPath: 'dfiles' },
    view: 'sys/dfiles/Index'
  },
  {
    path: '/sys/queue',
    name: 'sysQueue',
    meta: { layout: 'sys', requiresAuth: true, environment: 'sys', mainPath: 'queue' },
    view: 'sys/queue/Index'
  },
  {
    path: '/sys/booking',
    name: 'sysBooking',
    meta: { layout: 'sys', requiresAuth: true, environment: 'sys', mainPath: 'booking' },
    view: 'sys/booking/Index'
  },
  {
    path: '/sys/meteo',
    name: 'sysMeteo',
    meta: { layout: 'sys', requiresAuth: true, environment: 'sys', mainPath: 'meteo' },
    view: 'sys/meteo/Index'
  },
  {
    path: '/sys/turismo',
    name: 'sysTurismo',
    meta: { layout: 'sys', requiresAuth: true, environment: 'sys', mainPath: 'turismo' },
    view: 'sys/turismo/Index'
  },
  {
    path: '/sys/player',
    name: 'sysPlayer',
    meta: { layout: 'sys', requiresAuth: true, environment: 'sys', mainPath: 'players' },
    view: 'sys/player/Index'
  },
  {
    path: '/sys/player/:id',
    name: 'sysDtouchItem',
    meta: { layout: 'sys', requiresAuth: true, environment: 'sys', mainPath: 'player' },
    view: 'sys/player/Index'
  },
  {
    path: '/sys/prepare-url',
    name: 'sysPrepareURL',
    meta: { layout: 'sys', requiresAuth: true, environment: 'sys', mainPath: 'prepareURL' },
    view: 'sys/prepareURL/Index'
  },
  // INI dviewcontent
  /*{
    path: '/dviewContent/',
    name: 'dviewcontent',
    meta: { layout: 'private', requiresAuth: true, environment: 'dviewcontent', mainPath: 'empty' },
    view: 'private/Empty'
  },*/
  {
    path: '/dviewContent/',
    name: 'dviewcontentDashboard',
    meta: { layout: 'private', requiresAuth: true, environment: 'dviewcontent', mainPath: 'dashboard' },
    view: 'private/dviewcontent/dashboard/Index'
  },
  {
    path: '/dviewContent/:id',
    name: 'dviewContentItem',
    meta: { layout: 'private', requiresAuth: true, environment: 'dviewcontent', mainPath: 'dashboard' },
    view: 'private/dviewcontent/content/Index'
  },
  // END dviewcontent
  // INI dfiles
  {
    path: '/dfiles/',
    name: 'dfiles',
    meta: { layout: 'private', requiresAuth: true, environment: 'dfiles', mainPath: 'empty' },
    view: 'private/Empty'
  },
  {
    path: '/dfiles/dashboard',
    name: 'dfilesDashboard',
    meta: { layout: 'private', requiresAuth: true, environment: 'dfiles', mainPath: 'dashboard' },
    view: 'private/dfiles/Index'
  },
  {
    path: '/dfiles/users',
    name: 'dfilesUsers',
    meta: { layout: 'private', requiresAuth: true, environment: 'dfiles', mainPath: 'users' },
    view: 'private/dfiles/users/Index'
  },
  {
    path: '/dfiles/folders',
    name: 'dfilesFolders',
    meta: { layout: 'private', requiresAuth: true, environment: 'dfiles', mainPath: 'folders' },
    view: 'private/dfiles/folders/Index'
  },
  // END dfiles
  // INI queue
  {
    path: '/queue/',
    name: 'queue',
    meta: { layout: 'private', requiresAuth: true, environment: 'queue', mainPath: 'empty' },
    view: 'private/Empty'
  },
  {
    path: '/queue/dashboard',
    name: 'queueDashboard',
    meta: { layout: 'private', requiresAuth: true, environment: 'queue', mainPath: 'dashboard' },
    view: 'private/queue/Index'
  },
  {
    path: '/queue/manager',
    name: 'queueManager',
    meta: { layout: 'private', requiresAuth: true, environment: 'queue', mainPath: 'manager' },
    view: 'private/queue/manager/Index'
  },
  // END queue
  // INI dtouch
  {
    path: '/dtouch/',
    name: 'dtouch',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'empty' },
    view: 'private/Empty'
  },
  {
    path: '/dtouch/dashboard',
    name: 'dtouchDashboard',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'dashboard' },
    view: 'private/dtouch/Index'
  },
  {
    path: '/dtouch/personalization',
    name: 'dtouchPersonalization',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'personalization' },
    view: 'private/dtouch/personalization/Index'
  },
  {
    path: '/dtouch/setting',
    name: 'dtouchSetting',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'setting' },
    view: 'private/dtouch/setting/Index'
  },
  {
    path: '/dtouch/survey',
    name: 'dtouchSurvey',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'survey' },
    view: 'private/dtouch/survey/Index'
  },
  {
    path: '/dtouch/dish-orders',
    name: 'dishOrders',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'dishOrders' },
    view: 'private/dtouch/dishOrders/Index'
  },
  {
    path: '/dtouch/dish-orders-points/',
    name: 'dishOrdersPoints',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'dishOrders' },
    view: 'private/dtouch/dishOrders/points/Index'
  },
  {
    path: '/dtouch/welcome-form',
    name: 'dishOrders',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'welcomeForm' },
    view: 'private/dtouch/welcomeForm/Index'
  },

  {
    path: '/dtouch/request/manager',
    name: 'requestManager',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'requestManager', type: 'requests' },
    view: 'private/dtouch/requestsIssues/manager/Index'
  },
  {
    path: '/dtouch/request/result',
    name: 'requestResult',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'requestResult', type: 'requests' },
    view: 'private/dtouch/requestsIssues/result/Index'
  },
  {
    path: '/dtouch/issues/manager',
    name: 'issuesManager',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'issuesManager', type: 'issues' },
    view: 'private/dtouch/requestsIssues/manager/Index'
    //view: 'private/dtouch/issues/manager/Index'
  },
  {
    path: '/dtouch/issues/result',
    name: 'issuesResult',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'issuesResult', type: 'issues' },
    view: 'private/dtouch/requestsIssues/result/Index'
  },
  {
    path: '/dtouch/messages/',
    name: 'messagges',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'messages', type: 'messages' },
    view: 'private/dtouch/messages/Index'
  },
  {
    path: '/dtouch/interactive-map/',
    name: 'interactiveMap',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'interactiveMap', type: 'interactiveMap' },
    view: 'private/dtouch/interactiveMap/Index'
  },

  {
    path: '/dtouch/dev',
    name: 'dtouchdev',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'dev' },
    view: 'private/dtouch/dev/Index'
  },
  // END dtouch
  // INI book
  {
    path: '/booking/',
    name: 'booking',
    meta: { layout: 'private', requiresAuth: true, environment: 'booking', mainPath: 'empty' },
    view: 'private/Empty'
  },
  {
    path: '/booking/dashboard',
    name: 'bookingDashboard',
    meta: { layout: 'private', requiresAuth: true, environment: 'booking', mainPath: 'dashboard' },
    view: 'private/booking/Index'
  },
  {
    path: '/booking/places',
    name: 'bookingPlaces',
    meta: { layout: 'private', requiresAuth: true, environment: 'booking', mainPath: 'places' },
    view: 'private/booking/places/Index'
  },
  {
    path: '/booking/resources',
    name: 'bookingResources',
    meta: { layout: 'private', requiresAuth: true, environment: 'booking', mainPath: 'resources' },
    view: 'private/booking/resources/Index'
  },
  {
    path: '/booking/activities',
    name: 'bookingActivities',
    meta: { layout: 'private', requiresAuth: true, environment: 'booking', mainPath: 'activities' },
    view: 'private/booking/activities/Index'
  },
  {
    path: '/booking/activities/:id',
    name: 'bookingActivities',
    meta: { layout: 'private', requiresAuth: true, environment: 'booking', mainPath: 'activities' },
    view: 'private/booking/activities/Index'
  },
  {
    path: '/booking/reservations',
    name: 'bookReservations',
    meta: { layout: 'private', requiresAuth: true, environment: 'booking', mainPath: 'reservations' },
    view: 'private/booking/reservations/Index'
  },
  {
    path: '/booking/calendar',
    name: 'bookingCalendar',
    meta: { layout: 'private', requiresAuth: true, environment: 'booking', mainPath: 'calendar' },
    view: 'private/booking/calendar/Index'
  },
  {
    path: '/booking/relocate-reservations',
    name: 'relocateReservations',
    meta: { layout: 'private', requiresAuth: true, environment: 'booking', mainPath: 'relocateReservations' },
    view: 'private/booking/relocateReservations/Index'
  },
  {
    path: '/booking/attendance',
    name: 'attendance',
    meta: { layout: 'private', requiresAuth: true, environment: 'booking', mainPath: 'attendance' },
    view: 'private/booking/attendance/Index'
  },
  {
    path: '/booking/attendance-reservations',
    name: 'attendanceReservations',
    meta: { layout: 'private', requiresAuth: true, environment: 'booking', mainPath: 'attendanceReservations' },
    view: 'private/booking/attendanceReservations/Index'
  },
  // END book
  // INI turismo
  {
    path: '/turismo/',
    name: 'turismo',
    meta: { layout: 'private', requiresAuth: true, environment: 'turismo', mainPath: 'empty' },
    view: 'private/Empty'
  },
  {
    path: '/turismo/dashboard',
    name: 'turismoDashboard',
    meta: { layout: 'private', requiresAuth: true, environment: 'turismo', mainPath: 'dashboard' },
    view: 'private/turismo/Index'
  },
  {
    path: '/turismo/menus',
    name: 'turismoMenus',
    meta: { layout: 'private', requiresAuth: true, environment: 'turismo', mainPath: 'menus' },
    view: 'private/turismo/menus/Index'
  },
  {
    path: '/turismo/settings',
    name: 'turismoSettings',
    meta: { layout: 'private', requiresAuth: true, environment: 'turismo', mainPath: 'settings' },
    view: 'private/turismo/settings/Index'
  },
  {
    path: '/turismo/menu-check',
    name: 'menuCheck',
    meta: { layout: 'private', requiresAuth: true, environment: 'turismo', mainPath: 'menuCheck' },
    view: 'private/turismo/menuCheck/Index'
  },

  {
    path: '/turismo/survey-form',
    name: 'turismoSurveyForm',
    meta: { layout: 'private', requiresAuth: true, environment: 'turismo', mainPath: 'surveyForm' },
    view: 'private/turismo/surveyForm/Index'
  },
  {
    path: '/turismo/survey-result',
    name: 'turismoSurveyResult',
    meta: { layout: 'private', requiresAuth: true, environment: 'turismo', mainPath: 'surveyResult' },
    view: 'private/turismo/surveyResult/Index'
  },
  {
    path: '/turismo/stats',
    name: 'turismoStats',
    meta: { layout: 'private', requiresAuth: true, environment: 'turismo', mainPath: 'stats' },
    view: 'private/turismo/stats/Index'
  },
  // END turismo
  // INI status
  {
    path: '/status2/',
    name: 'status2',
    meta: { layout: 'private', requiresAuth: true, environment: 'status2', mainPath: 'status2' },
    view: 'private/status2/Index'
  },
  {
    path: '/status2/activityPlayer',
    name: 'status2ActivityPlayer',
    meta: { layout: 'private', requiresAuth: true, environment: 'status2', mainPath: 'activityPlayer' },
    view: 'private/status2/activityPlayer/Index'
  },
  {
    path: '/status/',
    name: 'status',
    meta: { layout: 'private', requiresAuth: true, environment: 'status', mainPath: 'dashboard' },
    view: 'private/status/Index'
  },
  {
    path: '/status/cron',
    name: 'status',
    meta: { layout: 'private', requiresAuth: true, environment: 'status', mainPath: 'statusCron' },
    view: 'private/status/Cron'
  },
  {
    path: '/status/dviewcontent/',
    name: 'statusDviewcontent',
    meta: { layout: 'private', requiresAuth: true, environment: 'status', mainPath: 'status' },
    view: 'private/status/TMP/dviewcontent/Index'
  },
  {
    path: '/status/force-schedule',
    name: 'forceSchedule',
    meta: { layout: 'private', requiresAuth: true, environment: 'status', mainPath: 'forceSchedule' },
    view: 'private/booking/forceSchedule/Index'
  },
  {
    path: '/status/router-logs',
    name: 'statusLogs',
    meta: { layout: 'private', requiresAuth: true, environment: 'status', mainPath: 'statusLogs' },
    view: 'private/status/routerLogs/Index'
  },
  {
    path: '/status/access-logs',
    name: 'accessLogs',
    meta: { layout: 'private', requiresAuth: true, environment: 'status', mainPath: 'accessLogs' },
    view: 'private/status/accessLogs/Index'
  },
  // END queue
  // INI no workspaces
  {
    path: '/dviewContent/no-ws',
    name: 'dviewContentNo-ws',
    meta: { layout: 'private', requiresAuth: true, environment: 'dviewcontent', mainPath: 'noWorkspaces' },
    view: 'private/NoWorkspaces'
  },
  {
    path: '/dtouch/no-ws',
    name: 'dtouchNo-ws',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'noWorkspaces' },
    view: 'private/NoWorkspaces'
  },
  {
    path: '/book/no-ws',
    name: 'bookNo-ws',
    meta: { layout: 'private', requiresAuth: true, environment: 'booking', mainPath: 'noWorkspaces' },
    view: 'private/NoWorkspaces'
  },
  {
    path: '/dfiles/no-ws',
    name: 'dfilesNo-ws',
    meta: { layout: 'private', requiresAuth: true, environment: 'dfiles', mainPath: 'noWorkspaces' },
    view: 'private/NoWorkspaces'
  },
  {
    path: '/turismo/no-ws',
    name: 'turismoNo-ws',
    meta: { layout: 'private', requiresAuth: true, environment: 'turismo', mainPath: 'noWorkspaces' },
    view: 'private/NoWorkspaces'
  },
  {
    path: '/status/no-ws',
    name: 'statusNo-ws',
    meta: { layout: 'private', requiresAuth: true, environment: 'status', mainPath: 'noWorkspaces' },
    view: 'private/NoWorkspaces'
  },
  // END no workspaces
  // INI no areas
  {
    path: '/dtouch/no-area',
    name: 'no-area',
    meta: { layout: 'private', requiresAuth: true, environment: 'dtouch', mainPath: 'noArea' },
    view: 'private/NoArea'
  },
  {
    path: '/book/no-area',
    name: 'no-area',
    meta: { layout: 'private', requiresAuth: true, environment: 'booking', mainPath: 'noArea' },
    view: 'private/NoArea'
  },
  {
    path: '/turismo/no-area',
    name: 'no-area',
    meta: { layout: 'private', requiresAuth: true, environment: 'turismo', mainPath: 'noArea' },
    view: 'private/NoArea'
  },
  {
    path: '/status/no-area',
    name: 'no-area',
    meta: { layout: 'private', requiresAuth: true, environment: 'status', mainPath: 'noArea' },
    view: 'private/NoArea'
  },
  {
    path: '/players/no-area',
    name: 'no-area',
    meta: { layout: 'private', requiresAuth: true, environment: 'players', mainPath: 'noArea' },
    view: 'private/NoArea'
  },
  // END no workspaces
  // ADMIN USER
  {
    path: '/users',
    name: 'users',
    meta: { layout: 'private', requiresAuth: true, mainPath: 'users' },
    view: 'private/users/Index'
  },
  {
    path: '/users/:id',
    name: 'usersItem',
    meta: { layout: 'private', requiresAuth: true, mainPath: 'users' },
    view: 'private/users/User'
  },
  {
    path: '*',
    // Relative to /src/views
    meta: { layout: 'private', requiresAuth: true },
    view: '404'
  }
]

