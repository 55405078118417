<template>
  <v-app id="inspire">
    <app-bar :onChangeDrawer="handleChangeDrawer" />
    <sidebar :showDrawer="drawer" />
    <main-view />
  </v-app>
</template>

<script>
  import MainView from '@/views/View.vue'
  import Sidebar from '@/components/Sidebar'
  import utils from '@/services/utils'
  import AppBar from './appBar/Index'
  export default {
    name: 'system-layout',
    components: {
      MainView,
      Sidebar,
      AppBar
    },
    data: () => ({ drawer: true }),
    mounted () {
      this.drawer = utils.getDrawer()
    },
    methods: {
      handleChangeDrawer () {
        this.drawer = !this.drawer
        utils.setDrawer(this.drawer)
      }
    }
  }
</script>

<style>
  #core-view {
    background-color: rgb(238,240,243);
  }
</style>
