<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{$t('common.messages.pleaseStandBy',locale)}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  export default {
    data () {
      return {
        dialog: false,
      }
    },
    computed: {
      ...mapState('app',['locale', 'loading']),
    },
    watch: {
      loading (v) {
        this.dialog = v
      },
      // dialog (val) {
      //   if (!val) return
      //   setTimeout(() => (this.dialog = false), 4000)
      // },
    },
  }
</script>
