<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-top-transition"
    max-width="600"
  >
    <!--template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
      >From the bottom</v-btn>
    </template-->
    <template>
    <v-alert
      v-if="mainAlert"
      :type="mainAlert.type ? mainAlert.type : 'error'"
      :icon="mainAlert.icon"
      border="left"
      prominent
      style="margin: 0px;"
    >
      <v-row align="center">
        <v-col class="grow">
          {{ mainAlert.message }}
        </v-col>
        <v-col class="shrink">
          <v-btn
      class="mx-2"
      fab
      dark
      small
      elevation="0"
      color="primary"
      @click="handleClose"
    >
      <v-icon dark>
        mdi-close-thick
      </v-icon>
    </v-btn>
          <!--v-btn @click="handleClose">
            {{ $t('common.close', locale) }}
          </v-btn-->
        </v-col>
      </v-row>
    </v-alert>
      <!--v-card>
        <v-card-text>
          <div class="text-h2 pa-12">{{ mainAlertMessage }}</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="handleClose"
            >{{ $t('common.close', locale) }}</v-btn>
        </v-card-actions>
      </v-card-->
    </template>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data () {
      return {
        dialog: false,
      }
    },
    computed: {
      ...mapState('app',['locale', 'mainAlert']),
    },
    watch: {
      mainAlert (v) {
        this.dialog = v
      },
    },
    methods: {
      handleClose () {
        this.$store.state.app.mainAlert = null
        this.dialog = false
      }
    },
  }
</script>
