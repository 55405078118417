<template>
  <v-main>
    <main-alert />
    <div id="core-view" style="height: 100%;">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </div>
    <!-- <div v-if="$route.meta.layout !== 'public'" style="height:70px"/>
    <core-footer v-if="$route.meta.layout !== 'public'" style="position:fixed;background-color:#eee" /> -->
  </v-main>
</template>

<script>
import MainAlert from './MainAlert'
export default {
  components: {
    MainAlert,
  },
  metaInfo () {
    return {
      title: 'ContentManager'
    }
  },
}
</script>

<style>
  #core-view {
    /* padding-bottom: 100px; */
  }
  html {
    scrollbar-width: none !important;
  }
</style>
