<template>
    <v-bottom-sheet v-model="sheet" inset persistent>
      <template v-slot:activator="{ on }">
        <v-btn
          block
          color="red"
          dark
          v-on="on"
        >
          {{ $t('login.changePassword') }}
        </v-btn>
      </template>
      <v-sheet class="text-center" height="250px">
        <v-container grid-list-md textCenter>
          <change-password-form
            :data="{}"
            :onSave="handleChangePassword"
            :onCancel="handleCancelChangePassword"
          />
        </v-container>
        <!-- <v-btn
          class="mt-6"
          text
          color="error"
          @click="sheet = !sheet"
        >close</v-btn>
        <div class="my-3">This is a bottom sheet using the inset prop</div> -->
      </v-sheet>
    </v-bottom-sheet>
</template>

<script>
  import ChangePasswordForm from './ChangePasswordForm'
  import utils from '@/services/utils.js'
  import api from '@/services/api'
  import * as sha1 from 'js-sha1'
  export default {
    components: {
      ChangePasswordForm
    },
    props: {
      onChangeMessage: {
        type: Function,
        required: false,
        default: null
      }
    },
    data: () => ({
      sheet: false,
    }),
    computed: {
      userID () {
        return utils.getUser()
      },
    },
    methods: {
      handleCancelChangePassword () {
        this.sheet = false
      },
      handleChangePassword (oldPassword, newPassword) {
        const body = {
          oldPassword: sha1(oldPassword),
          newPassword: sha1(newPassword)
        }
        api.updateItem('system', 'v1/internal/users/', this.userID.concat('/change-psw'), body)
          .then(response => {
            if (!response.data.error) {
              this.sheet = false
              this.onChangeMessage(this.$t('changePassword.messages.success'), 'success')
            } else {
              this.onChangeMessage(this.$t(`changePassword.messages.${response.data.message}`), 'error')
            }
          })
          .catch(error => {
            console.log('error', error)
          })
      }
    }
  }
</script>

