import api from './api'
import store from '@/store'

const utils = {
  handleCheckRelocateReservations (environment, v) {
    api.getItem (environment, `v1/internal/workspaces/`, v.concat('/relocate-reservations'))
      .then(response => {
        store.state.app.sidebarBadges = store.state.app.sidebarBadges.filter(x => x.environment !== environment && name !== 'relocateReservations')
        if(response && response.count > 0) {
          store.state.app.sidebarBadges.push({
            environment: environment,
            name: 'relocateReservations',
            content: response.count,
          })
        }
      })
  },
}

export default utils

