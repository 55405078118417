import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from '@/plugins/i18n/i18n'
import router from '@/router'
import Private from '@/layouts/private/Index.vue'
import Public from '@/layouts/public/Index.vue'
import System from '@/layouts/sys/Index.vue'
import store from '@/store'
import api from '@/services/api'
import { sync } from 'vuex-router-sync'
import VueKonva from 'vue-konva'
import VueHtml2Canvas from 'vue-html2canvas'

import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'


Vue.use(VueKonva)
Vue.use(VueHtml2Canvas)

Vue.config.productionTip = false

Vue.component('private-layout', Private)
Vue.component('public-layout', Public)
Vue.component('sys-layout', System)

sync(store, router)

router.beforeEach((to, from, next) => {
  // if(!store.state.app.environment) store.state.app.environment = to.meta.environment
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!api.getToken()) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else next()
  } else next()
})

Vue.use(new VueSocketIO({
    debug: true,
    connection: SocketIO(process.env.VUE_APP_ENV === 'development' ? 'http://localhost:3200' : 'https://cm-queue-api.dviewcontent.com', { withCredentials: false, autoConnect: false }),
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    },
}))

new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
