/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [  
  {
    path: '/totem',
    // Relative to /src/views
    name: 'path.dashboard.title',
    meta: { layout: 'public' },
    view: 'public/totem/Index'
  },
  {
    path: '/',
    // Relative to /src/views
    meta: { layout: 'public' },
    view: 'public/Home'
  },
  {
    path: '/test',
    // Relative to /src/views
    meta: { layout: 'public' },
    view: 'public/Test'
  },
  {
    path: '*',
    // Relative to /src/views
    meta: { layout: 'public' },
    view: '404'
  }
]
