import tinycolor from 'tinycolor2'
import constants from '@/constants'
import langs from '@/constants/ISOLangs'
import availability from './availability'
const utils = {
  isMobile () {
    if (window.location.hash.indexOf('/totem') >= 0) return false
    if (navigator.userAgent.match(/iPad/i) != null) return false
    if (navigator.userAgent.match(/Mobi/)) return true
    if ('screen' in window && window.screen.width <= 800 && window.screen.height <= 600) return true

    var connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
    if (connection && connection.type === 'cellular') return true

    return false
  },
  isTotem () {
    return screen && navigator && screen.width===1080 && screen.height===1920 && navigator.userAgent.indexOf('Windows') != -1 && navigator.userAgent.indexOf('Chrome') != -1
  },
  urlExists(url) {
    try {
      const http = new XMLHttpRequest()
      http.open('HEAD', url, false)
      http.send()
      return http.status != 404
    } catch {
      return false
    }
  },
  isIpad () {
    return navigator.userAgent.match(/iPad/i) != null
  },
  getNewID () {
    const uuidv4 = require('uuid/v4')
    return uuidv4()
  },
  clone (v) {
    return v ? JSON.parse(JSON.stringify(v)) : null
  },
  validateEmail (v) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v)
  },
  getYearsByDays (v) {
    let result = ''
    let year = (v / 365) | 0
    if (year > 0) result += `${year}y `
    let days = v - (year*365) | 0
    result += `${days}d`
    return result
  },
  addMinutes(date, minutes) {
    const d = typeof date === 'string' ? new Date(date) : date
    return new Date(d.getTime() + minutes * 60000)
  },
  getOppositeColor (v) {
    return tinycolor(v).isLight() ? '#000000' : '#FFFFFF'
  },
  getWorkspaceIdentification () {
    const domainNotValid = ['localhost']
    const domain = this.getDomain()
    return domainNotValid.indexOf(domain) >= 0 ? this.getWorkspace('dtouch') : domain
  },
  getDomain () {
    const getHostname = window.location.hostname
    return getHostname.split('.')[0] === 'www' ? getHostname.split('.')[1] : getHostname.split('.')[0]
  },
  checkDate (v) {
    return typeof v === 'object' 
      ? new Date().toISOString().substr(0, 10) 
      : new Date(v).toISOString().substr(0, 10)
  },
  getLastDayOfMonth(date) {
    const year = date.getFullYear()
    const month = date.getMonth()
    return new Date(year, month + 1, 0)
  },
  checkIsBase64 (v) {
    return v.indexOf('data:') === 0
  },
  isPrivate () {
    return true
//    const host = window.location.host
//    const parts = host.split('.')
//    const domainLength = 3 // route1.example.com => domain length = 3
//    if (parts.length === (domainLength - 1)) {
//      return true
//    } else if (parts[0] === 'www') {
//      if (parts[0] === 'www' && (parts[1] === 'admin' || parts[1] === process.env.VUE_APP_URL)) {
//        return true
//      }
//    } else if (parts[0] === 'admin') {
//      return true
//    }
//    return false
  },
  checkJSON(v) {
    return typeof v == 'object' ? v : JSON.parse(v)
  },
  getTranslation (value, locale) {
    if (!value) return 
    let aux = typeof value == 'object' ? value : JSON.parse(value)
    if (!aux) return
    if (aux[locale]) return this.convertEspecialChar(aux[locale])
    else if (aux['en']) return this.convertEspecialChar(aux['en'])
    else {
      const firstKey = Object.keys(aux)[0]
      return this.convertEspecialChar(aux[firstKey])
    }
  },
  getAllLanguages (selectedLanguages) {
    const languages = []
    for (var key in langs.isoLangs) {
      languages.push({
        ID: key,
        Name: langs.isoLangs[key].nativeName.split(', ').join('-'),
        NormalName: langs.isoLangs[key].name.split(', ').join('-'),
      })
    }
    if (selectedLanguages) {
      return languages.filter(x => selectedLanguages.indexOf(x.ID) >= 0)
    }
    return languages
  },
  replaceAll(string, search, replace) {
    return string ? string.split(search).join(replace) : ''
  },
  convertTime (v) {
    if (!v) return '00:00'

    const h = v / 60 | 0
    const m = v - (h * 60)
    return `${this.pad(h)} : ${this.pad(m)}`
  },
  convertTimeDetail (v) {
    if (!v) return '00:00'

    const h = v / 60 | 0
    const m = v - (h * 60)
    return h > 0 ? `${this.pad(h)} h. ${this.pad(m)} min.` : `${this.pad(m)} min.`
  },
  getMinutesToTimeFormated (num) {
    let d = Math.floor(num/1440) // 60*24
    let h = Math.floor((num-(d*1440))/60)
    let m = Math.round(num%60)

    if (d > 0) return `${d}d. ${h}h. ${m}m.`
    else return `${h}h. ${m}m.`
  },
  gcd (a, b) {
    return (b == 0) ? a : this.gcd (b, a%b)
  },
  getAspectRatio(w, h) {
    const r = this.gcd(w, h)
    return (w/r)/(h/r)
  },
  getNewPassword () {
    const dictionary = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z","0","1","2","3","4","5","6","7","8","9",".","-","_","$","&","#","@"]
    let aux = ''
    while (aux.length < 8)
      aux += dictionary[parseInt(Math.random()*dictionary.length)]

    return aux
  },
  getIconDviewcontentType(v) {
    const aux = constants.dviewcontentTypes.filter(x => x.value === v)
    return (aux.length > 0 ? aux[0].icon : 'mdi-plus')
  },
  convertEspecialChar (value) {
    return value ? value.split('#$#').join("'") : null
  },
  setDrawer (v) {
    localStorage.setItem('drawer', v ? 'true' : 'false')
    return
  },
  getDrawer () {
    const aux = localStorage.getItem('drawer')
    return aux && aux.length > 0 ? aux === 'true' : true
  },
  setCustomToken (key, value) {
    localStorage.setItem(key, value)
  },
  getCustomToken (key) {
    const aux = localStorage.getItem(key)
    return aux && aux.length > 0 ? aux : null
  },
  setWorkspace (environment, v) {
    localStorage.setItem(`${environment}Workspace`, v)
  },
  getWorkspace (environment) {
    return localStorage.getItem(`${environment}Workspace`)
  },
  setUser (v) {
    localStorage.setItem('user', v ? v : '')
    return
  },
  getUser () {
    const aux = localStorage.getItem('user')
    return aux && aux.length > 0 ? aux : null
  },
  checkIsActualUser (v) {
    return v === this.getUser()
  },
  isLight () {
    const c = c.substring(1)      // strip #
    const rgb = parseInt(c, 16)   // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff  // extract red
    const g = (rgb >>  8) & 0xff  // extract green
    const b = (rgb >>  0) & 0xff  // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

    // if (luma < 40) {
    //     // pick a different colour
    // }
    return luma > 128
  },
  convertMinutesToTime (v) {
    let aux = v / 60
    const hours = aux > 0 ? parseInt(aux) : 0
    const minutes = v - (hours * 60)
    return {
      hours,
      minutes
    }
  },
  pad (value, nItem = 2) {
    let aux = ''
    for (let i = 0; i < nItem; i++) aux = aux.concat('0')
    let result = aux.concat(value)
    result = result.substring(result.length - nItem, result.length)
    return result
  },
  checkAvailability (v, daysToAdd = 0, timeZone = 'Europe/London') {
    return availability.check(v, daysToAdd, timeZone)
  },
  checkIsActive (v, daysToAdd = 0, timeZone = 'Europe/London') {
    return availability.checkIsActive(v, daysToAdd, timeZone)
  },
  getScale (aw, ah, bw, bh) {
    let scale = 1
    if (aw/bw < ah/bh) { // determinamos si el mayor scale factor es el width o el height
      if (aw/bw < 1) scale = aw/bw
    } else {
      if (ah/bh < 1) scale = ah/bh
    }

    // una vez determinado el scale factor actualizamos el width y height
    return { 
      width: bw * scale, 
      height: bh * scale 
    }
  },
  rnd (a, b) {
    return Math.floor((b - a + 1) * Math.random()) + a
  },
  getParameterByName(name, url) {
    let result = null
    if (!url) url = window.location.href
    name = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    result = regex.exec(url)
    if (!result) return null
    if (!result[2]) return ''
    result = decodeURIComponent(result[2].replace(/\+/g, ' '))
    return result && result.length > 0 ? result : null
  },
}

export default utils

